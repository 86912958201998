class SessionValidation {

    private sessionValidationData: any;

    constructor() {
        this.sessionValidationData = {};
    }


    setSessionValidationResponse(sessionData: any) {
        this.sessionValidationData = sessionData;
    }

    getSessionValidationResponse() {
        return this.sessionValidationData;
    }
}

export const sessionValidationApi = new SessionValidation();
